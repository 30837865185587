import firebase from "firebase/app";
import "firebase/firestore";

var firebaseConfig = {
        apiKey: "AIzaSyAvRxHZjDJ-SBXdVZckNpw2Wr9bhHMZvwM",
        authDomain: "casopisscientas.firebaseapp.com",
        projectId: "casopisscientas",
        storageBucket: "casopisscientas.appspot.com",
        messagingSenderId: "443657789220",
        appId: "1:443657789220:web:d3bea25dfe1b0305249db1"
};

const firebaseApp = firebase.initializeApp(firebaseConfig);
const timestamp = firebase.firestore.FieldValue.serverTimestamp;

export { timestamp };
export default firebaseApp.firestore();
